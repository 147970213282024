import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useActiveProduct } from "@/core/context/ActiveProductContext"
import { useAuthUser } from "@/core/context/AuthUserContext"
import { useFormStore } from "@/core/form/store/FormStore"
import ROUTE_NAMES from "@/core/route/util/routeNames"
import { useProductSlug } from "@/core/route/util/routeUtils"
import {
  MembershipSessionProvider_recordSessionLocationMutation,
  StateMembershipSessionActivityArea,
} from "@/core/session/__generated__/MembershipSessionProvider_recordSessionLocationMutation.graphql"
import useDebounce from "@utils/hook/useDebounce"
import useInterval from "@utils/hook/useInterval"
import { observer } from "mobx-react-lite"
import { createContext, ReactNode, useContext, useEffect, useRef } from "react"
import { useLocation } from "react-router"
import { graphql } from "relay-runtime"

const SESSION_INACTIVITY_TIME_SECONDS = 10 * 60 // 10 minutes in seconds
const SESSION_HEARTBEAT_INTERVAL_MS = 60 * 1000 // one minute in miliseconds

interface MembershipSessionProviderProps {
  children: ReactNode
}

function MembershipSessionProvider({ children }: MembershipSessionProviderProps) {
  const location = useLocation()
  const { authUser } = useAuthUser()
  const activeOrganization = useActiveOrganization()
  const activeProduct = useActiveProduct()
  const currentProductSlug = useProductSlug()

  /** Whether or not the user is actively using the platform */
  const lastActivityAtRef = useRef<Date>(new Date())
  const areaRef = useRef<StateMembershipSessionActivityArea>(getAreaByPath())

  //* Initialize
  useEffect(() => {
    // Only restart countdown when page becomes visible AND was previously hidden
    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible" && document.hidden === false) {
        debounceSetLastActivityAt()
      }
    }

    // Add event listeners to keep track of user activity
    window.addEventListener("resize", debounceSetLastActivityAt)
    document.addEventListener("click", debounceSetLastActivityAt)
    document.addEventListener("mousemove", debounceSetLastActivityAt)
    document.addEventListener("keydown", debounceSetLastActivityAt)
    document.addEventListener("touchstart", debounceSetLastActivityAt)
    document.addEventListener("scroll", debounceSetLastActivityAt)
    document.addEventListener("visibilitychange", handleVisibilityChange)

    return () => {
      window.removeEventListener("resize", debounceSetLastActivityAt)
      document.removeEventListener("click", debounceSetLastActivityAt)
      document.removeEventListener("mousemove", debounceSetLastActivityAt)
      document.removeEventListener("keydown", debounceSetLastActivityAt)
      document.removeEventListener("touchstart", debounceSetLastActivityAt)
      document.removeEventListener("scroll", debounceSetLastActivityAt)
      document.removeEventListener("visibilitychange", handleVisibilityChange)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Update + Record location if we change areas
  useEffect(() => {
    // Update the area if we change paths
    const previousArea = areaRef.current
    const currentArea = getAreaByPath()
    areaRef.current = currentArea

    const shouldRecordLocation = checkShouldRecordLocation()
    if (!shouldRecordLocation) return

    if (previousArea === currentArea) return // No need to log if we are in the same area
    recordSessionLocation()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname])

  // Record location if we change products
  useEffect(() => {
    const shouldRecordLocation = checkShouldRecordLocation()
    if (!shouldRecordLocation) return

    recordSessionLocation()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeProduct?.id])

  // Record location every minute
  useInterval(() => {
    const shouldRecordLocation = checkShouldRecordLocation()
    if (!shouldRecordLocation) return

    recordSessionLocation()
  }, SESSION_HEARTBEAT_INTERVAL_MS)

  const recordSessionLocationForm =
    useFormStore<MembershipSessionProvider_recordSessionLocationMutation>(
      graphql`
        mutation MembershipSessionProvider_recordSessionLocationMutation(
          $input: RecordSessionLocationInput!
        ) {
          response: recordSessionLocation(input: $input) {
            errors {
              field
              message
            }
          }
        }
      `,
      {
        area: getAreaByPath(),
        productId: activeProduct?.id,
      },
      {
        showErrorToast: false,
      }
    )

  const debounceSetLastActivityAt = useDebounce(() => {
    setLastActivityAt()
  }, 500)

  return (
    <MembershipSessionContext.Provider value={{ setLastActivityAt }}>
      {children}
    </MembershipSessionContext.Provider>
  )

  function recordSessionLocation() {
    recordSessionLocationForm.submit({
      area: getAreaByPath(),
      productId: activeProduct?.id,
    })
  }

  // Restarts the counter while keeping the countdown interval running
  function setLastActivityAt() {
    lastActivityAtRef.current = new Date()
  }

  function getAreaByPath(): StateMembershipSessionActivityArea {
    const curriculumRegex = new RegExp(
      `^${ROUTE_NAMES.PRODUCT.CURRICULUM.ROOT.replace(/:[^/]+/g, "([^/]+)")}`
    )
    if (curriculumRegex.test(location.pathname)) return "curriculum"

    if (activeProduct) return "product" // If inside product, default to product
    return "community" // If not inside product, default to community
  }

  function currentProductSlugMatchesActiveProductSlug() {
    if (!currentProductSlug) return true
    return currentProductSlug === activeProduct?.slug
  }

  function checkShouldRecordLocation() {
    const isInactive =
      lastActivityAtRef.current <=
      new Date(Date.now() - SESSION_INACTIVITY_TIME_SECONDS * 1000)

    if (!authUser) return false
    if (!activeOrganization) return false
    if (!activeOrganization.viewerMembership) return false
    if (isInactive) return false
    if (!currentProductSlugMatchesActiveProductSlug()) return false // If we are on product route, make sure activeProduct context has loaded or else the correct productId won't get sent

    return true
  }
}

type MembershipSessionContextValue = {
  setLastActivityAt: () => void
}

const MembershipSessionContext = createContext<MembershipSessionContextValue | null>(null)

export const useSession = (): MembershipSessionContextValue => {
  const context = useContext(MembershipSessionContext)
  return context!
}

export default observer(MembershipSessionProvider)
